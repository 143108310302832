
import { Options, Vue } from "vue-class-component"
import Input from "@/components/Input/index.vue"
import ButtonsWrap from "@/components/Button/ButtonsWrap.vue"
import UnsubscribeService from "@/api/services/UnsubscribeService"
import { Unsubscribe } from "@/models/Unsubscribe"

@Options({
  components: {
    Input,
    ButtonsWrap
  }
})
export default class UnsubscribeDaily extends Vue {
  unsubscribed = false
  canceled = false

  get id(): string {
    return this.$route.query.id as string
  }

  get status(): string {
    return this.$route.query.status as string
  }
  onConfirm(): void {
    const payload: Unsubscribe = Unsubscribe.create({
      type: "another",
      description: ""
    })

    UnsubscribeService.unsubscribeDailyDigestById(this.id, payload)

    this.unsubscribed = true
    return
  }

  onCancel() {
    this.canceled = true
  }
}
